import React from "react"
import cx from 'classnames'

import BlockContent from "@sanity/block-content-to-react"
import { Serializer } from "src/utils/serializer"
import { Image } from 'src/components/image'

export interface AboutImageAndTextProps {
  data: {
    image: {
      imageId: string
    }
    title: string
    text: any[]
    color?: string
    alignment?: string
  }
}

export const AboutImageAndText = ({ data }: AboutImageAndTextProps) => {
  const {
    text,
    title,
    videoUrl,
    image,
    alignment,
    color
  } = data
  return (
    <div className={`x al two__column-about color--lite-yellow bg--${color}`}>
      {alignment !== 'full-width' ? (
        <div className='container--xl mxa'>
          <div className={cx('row gutter--none two__column-alt pr jcb df fw', alignment)}>
            <div className='col c14 c7--800'>
              <div className='df jcb fdc y'>
                <div className='p2 p4--1000'>
                  <h2 className='s60--800'>{title}</h2>
                </div>
                <div className='p2 p4--1000'>
                  <BlockContent blocks={text} serializers={Serializer} />
                </div>
              </div>
            </div>
            <div className='col c14 c7--800'>
              <div className='p2 p4--1000'>
                <div className='pr'>
                  {videoUrl && (
                    <video className='pa x y top object-fit left z1' autoPlay={true} muted={true} loop={true} playsInline={true}>
                      <source src={videoUrl} />
                    </video>
                  )}
                  <Image className='x' imageId={image.imageId} />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='x pr about__image-text pb8--800'>
          <div className='container--1000 pt6--800 pb8 mxa p2 pr tc color--green z2'>
            <div className='m2'>
              <h2 className='founders s80'>{title}</h2>
            </div>
            <div className='m2'>
              <div className=' container--700 mxa'>
                <BlockContent blocks={text} serializers={Serializer} />
              </div>
            </div>
          </div>
          <Image className='pa y top left x' imageId={image.imageId} />
        </div>
      )}
    </div>
  )
}

export default AboutImageAndText